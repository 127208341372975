import React from 'react';
import {FormattedMessage} from 'react-intl';

import keyEventsIconURL from './entry-icon.png';
import keyEventsInsetIconURL from './inset-icon.svg';

/* const translationMap = {
    'ja': {
        'gui.extension.keyEvents.description': 'キーボードイベントを拡張する'
    },
    'ja-Hira': {
        'gui.extension.keyEvents.description': 'キーボードイベントをかくちょうする'
    }
}; */

const entry = {
    name: 'MMN 按鈕事件',
    extensionId: 'keyEvents',
    extensionURL: null,
    collaborator: 'NCKU MMN Lab',
    iconURL: keyEventsIconURL,
    insetIconURL: keyEventsInsetIconURL,
    description: (
        <FormattedMessage
            defaultMessage="讓你的專案模擬按鈕按下的功能吧。"
            description="Description for keyEvents extension for Xcratch"
            id="gui.extension.keyEvents.description"
        />
    ),
    featured: true,
    disabled: false,
    bluetoothRequired: false,
    internetConnectionRequired: true,
    helpLink: 'https://github.com/yokobond/xcx-key-events/'
    // translationMap: translationMap
};

export {entry}; // loadable-extension needs this line.
export default entry;
